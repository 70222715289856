<template>
  <div class="container fluid recovery">
    <div class="row d-flex justify-center align-center w-100 mt-16">
      <div class="col d-flex flex-column align-center">
        <router-link :to="{ name: 'login' }">
          <v-img
            src="@/assets/img/favicon.png"
            max-height="200"
            max-width="200"
          ></v-img>
        </router-link>
        <h3>Reset Password</h3>
        <v-form ref="form" class="w-50">
          <div class="row d-flex justify-center align-center mt-16">
            <div class="col-3 d-flex flex-column align-end">
              <label for="" class="email-label">Token: </label>
            </div>
            <div class="col-9">
              <v-text-field
                label="Token"
                v-model="token"
                :rules="[rules.required]"
              />
            </div>
          </div>
          <div class="row d-flex justify-center align-center">
            <div class="col-3 d-flex flex-column align-end">
              <label for="" class="email-label">Email: </label>
            </div>
            <div class="col-9">
              <v-text-field
                label="Email"
                v-model="email"
                :rules="[rules.required, rules.email]"
              />
            </div>
          </div>
          <div class="row d-flex justify-center align-center">
            <div class="col-3 d-flex flex-column align-end">
              <label for="" class="email-label">New password: </label>
            </div>
            <div class="col-9">
              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                label="New password"
                counter
                :rules="[rules.required, rules.password]"
                @click:append="showPassword = !showPassword"
                autocomplete="off"
              ></v-text-field>
            </div>
          </div>
          <div class="row d-flex justify-center align-center mt-10">
            <v-btn color="primary" @click="handleSubmit">Reset Password</v-btn>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import form from "@/mixins/form";

export default {
  mixins: [form],
  data() {
    return {
      token: "",
      password: "",
      email: "",
      showPassword: false,
    };
  },
  methods: {
    pushTo() {
      this.$router.push({ name: "emailSent" });
    },
    handleSubmit() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("login/resetPassword", {
            email: this.email,
            token: this.token,
            password: this.password,
          })
          .then(() => {
            this.$toast.success("password has been modified");
            this.$router.push({ name: "login" });
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
  },
  mounted() {
    this.token = this.$route.query.token;
  },
};
</script>

<style lang="sass">
.email-label
  font-weight: 500
  font-size: 1rem
.recovery
  height: 100vh
h3
  color: #23c577
</style>